<template>
  <b-modal
    id="clientSelectorModal"
    ref="clientSelectorModal"
    title="Client selector"
    ok-only
    ok-title="Save client selection"
    size="lg"
    @ok="saveData"
  >
    <div v-if="clientData">
      <!-- form -->

      <status-alert
        :is-success="isSuccess"
        :error-data="errorData"
        :success-msg="successMsg"
      />
      <b-row>
        <b-col
          md="12"
          lg="12"
        >
          <validation-observer

            ref="formClient"
          > <b-form
            class="p-2"
          >
            <validation-provider
              #default="{errors}"
              :name="$t('Client/Company Name')"
              rules="required"
            >
              <!-- Name -->
              <b-col cols="12">

                <b-form-group
                  label="Client/Company Name"
                  label-for="client-name"
                  label-cols-md="4"
                >

                  <vue-autosuggest
                    v-model="clientData.name"
                    :suggestions="clientSuggest.filteredClients"
                    :input-props="clientSuggest.inputProps"
                    :state="errors.length > 0 ? false:null"
                    :value="clientData.name"
                    @selected="clientSelected"
                    @input="clientOnInputChanged"
                  >
                    <template slot-scope="{suggestion}">
                      <div class="d-flex align-items-center">

                        <div class="detail ml-50">
                          <b-card-text class="mb-0">
                            <h5>    {{ suggestion.item.name }}</h5>

                          </b-card-text>
                          <small class="text-muted">
                            {{ suggestion.item.email }}
                          </small>
                        </div>
                      </div>
                    </template>
                  </vue-autosuggest>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-col>
            </validation-provider>
            <!--/ Name -->
            <!-- Email -->
            <validation-provider
              #default="{errors}"
              :name="$t('Email')"
              rules="required|email"
            >
              <!-- Name -->
              <b-col cols="12">

                <b-form-group
                  label="Email"
                  label-for="email"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="email"
                    v-model="clientData.email"
                    size="lg"
                    placeholder="Enter a email..."
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-col>
            </validation-provider>
            <!--/ Email -->
            <!-- billing address -->
            <b-col cols="12">
              <b-form-group
                label="Billing address"
                label-for="billing-address"
                label-cols-md="4"
              >
                <b-form-textarea
                  id="billing-address"
                  v-model="clientData.address"
                  size="lg"
                  rows="1"
                  placeholder="Enter a description"
                />
              </b-form-group>
            </b-col>
            <!--/ billing address-->

            <!-- phone -->
            <validation-provider
              #default="{errors}"
              :name="$t('Phone')"
              rules=""
            >

              <b-col cols="12">

                <b-form-group
                  label="Phone"
                  label-for="phone"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="phone"
                    v-model="clientData.phone"
                    v-numeric-only
                    type="number"
                    size="lg"
                    placeholder="Enter a phone..."
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-col>
            </validation-provider>
            <!--/ phone-->
            <!-- mobile -->
            <validation-provider
              #default="{errors}"
              :name="$t('Mobile')"
              rules=""
            >

              <b-col cols="12">

                <b-form-group
                  label="Mobile"
                  label-for="mobile"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="mobile"
                    v-model="clientData.mobile"
                    size="lg"
                    placeholder="Enter a mobile number..."
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-col>
            </validation-provider>
            <!--/ mobile-->

            <!-- Tax number -->
            <validation-provider
              #default="{errors}"
              :name="$t('Tax number')"

              rules=""
            >

              <b-col cols="12">

                <b-form-group
                  label="Tax number"
                  label-for="tax-number"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="tax-number"
                    v-model="clientData.tax_number"
                    size="lg"
                    placeholder="Enter a tax number..."
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-col>
            </validation-provider>
          <!--/ tax number-->

          </b-form>
          </validation-observer>
        </b-col>

      </b-row>

    </div>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BFormTextarea, BFormInvalidFeedback, BCardText, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import { VueAutosuggest } from 'vue-autosuggest'
import clientStoreModule from '../client/clientStoreModule'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    VueAutosuggest,
    BCardText,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      clientSuggest: {
        limit: 10,
        filteredClients: [],
        inputProps: {
          id: 'client_id',
          class: 'form-control',
          placeholder: 'Enter a client name...',

        },

      },
      currencyClaveOptions: null,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Client Updated',
      errorData: null,
      blankClientData: {
        id: 0,
        address: '',
        email: '',
        mobile: '',
        name: '',
        phone: '',
        tax_number: '',
      },
      clientData: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  created() {
    this.refreshGlobalParams()
    this.resetForm()
    this.clientOnInputChanged('')
  },
  methods: {
    refreshGlobalParams() {
      store
        .dispatch('app/refreshGlobalParams')
    },
    showEditor(clientData) {
      if (clientData) {
        this.clientData = JSON.parse(JSON.stringify(clientData))
    }

      this.$refs.clientSelectorModal.show()
    },
    closeEditor() {
      this.$refs.clientSelectorModal.hide()
      this.resetForm()
    },
    clientSelected(option) {
      this.clientData.id = option.item.id
      this.clientData.address = option.item.billing_address
      this.clientData.email = option.item.email
      this.clientData.mobile = option.item.mobile
      this.clientData.name = option.item.name
      this.clientData.phone = option.item.phone
      this.clientData.tax_number = option.item.tax_number
    },
    clientOnInputChanged(text) {
      if (text === undefined) {
        return
      }
      store.dispatch('app/suggestClients', { term: text })
        .then(response => {
          this.clientSuggest.filteredClients = [{
            data: response.data.data,
          }]
        })
    },
    cancel() {
      this.$emit('closed')
    },
    deleteData() {
      this.$emit('clientDeleted', this.clientData)
    },
    saveData() {
      this.$refs.formClient.validate().then(success => {
        if (success) {
          if (this.clientData.id === 0) {
            this.clientData.email = this.clientData.email.trim()
            store
              .dispatch('app-client/addClient', this.clientData)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.clientData.id = response.data.data.id
                this.errorData = null
                this.$emit('clientSelected', this.clientData)
              })
              .catch(error => {
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          } else {
            this.$emit('clientSelected', this.clientData)
          }
        }
      })
    },
    resetForm() {
      this.clientData = JSON.parse(JSON.stringify(this.blankClientData))
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
