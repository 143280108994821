/* eslint-disable global-require */
export default {

  themes: [
    {
      name: 'standard', label: 'Standard', img: require('./assets/theme-thumbs/standard.jpg'), component: () => import('./standard/Template.vue'),
    },

    {
      name: 'modern', label: 'Modern', img: require('./assets/theme-thumbs/modern.jpg'), component: () => import('./modern/Template.vue'),
    },
    {
      name: 'corporate', label: 'Corporate', img: require('./assets/theme-thumbs/modern.jpg'), component: () => import('./corporates/Template.vue'),
    },
    /*
   {
     name: 'typewriter', label: 'Typewriter', img: require('./assets/theme-thumbs/typewriter.jpg'), component: () => import('./typewriter/Template.vue'),
   },
   */
  ],
  colors: [
    'rgb(168, 53, 65)',
    'rgb(199, 80, 92)',
    'rgb(228, 97, 110)',
    'rgb(250, 120, 133)',
    'rgb(255, 143, 154)',
    'rgb(182, 63, 38)',
    'rgb(212, 75, 46)',
    'rgb(236, 94, 64)',
    'rgb(244, 111, 83)',
    'rgb(255, 139, 114)',
    'rgb(198, 24, 0)',
    'rgb(243, 41, 41)',
    'rgb(245, 73, 73)',
    'rgb(255, 128, 107)',
    'rgb(255, 186, 174)',
    'rgb(212, 0, 92)',
    'rgb(255, 0, 111)',
    'rgb(255, 107, 156)',
    'rgb(255, 145, 181)',
    'rgb(255, 198, 223)',
    'rgb(142, 21, 107)',
    'rgb(182, 44, 145)',
    'rgb(214, 72, 176)',
    'rgb(241, 95, 202)',
    'rgb(255, 124, 224)',
    'rgb(118, 52, 144)',
    'rgb(156, 100, 179)',
    'rgb(181, 116, 208)',
    'rgb(198, 127, 227)',
    'rgb(215, 138, 247)',
    'rgb(114, 75, 218)',
    'rgb(151, 112, 255)',
    'rgb(172, 141, 255)',
    'rgb(188, 163, 255)',
    'rgb(199, 177, 255)',
    'rgb(16, 78, 168)',
    'rgb(33, 119, 207)',
    'rgb(58, 156, 240)',
    'rgb(84, 193, 255)',
    'rgb(112, 203, 255)',
    'rgb(51, 110, 179)',
    'rgb(88, 149, 220)',
    'rgb(127, 185, 252)',
    'rgb(160, 203, 253)',
    'rgb(191, 220, 254)',
    'rgb(51, 139, 166)',
    'rgb(79, 165, 192)',
    'rgb(110, 194, 220)',
    'rgb(128, 214, 241)',
    'rgb(158, 232, 255)',
    'rgb(4, 128, 119)',
    'rgb(52, 152, 153)',
    'rgb(82, 188, 189)',
    'rgb(93, 216, 217)',
    'rgb(105, 241, 242)',
    'rgb(30, 187, 99)',
    'rgb(48, 208, 118)',
    'rgb(52, 227, 129)',
    'rgb(55, 239, 136)',
    'rgb(53, 255, 142)',
    'rgb(29, 111, 0)',
    'rgb(42, 144, 0)',
    'rgb(63, 169, 0)',
    'rgb(96, 205, 34)',
    'rgb(153, 224, 101)',
    'rgb(255, 132, 31)',
    'rgb(255, 166, 0)',
    'rgb(255, 170, 86)',
    'rgb(255, 214, 64)',
    'rgb(255, 242, 0)',
    'rgb(133, 50, 0)',
    'rgb(176, 89, 15)',
    'rgb(206, 123, 31)',
    'rgb(239, 151, 50)',
    'rgb(255, 176, 63)',
    'rgb(112, 95, 30)',
    'rgb(151, 133, 61)',
    'rgb(196, 178, 104)',
    'rgb(222, 202, 119)',
    'rgb(253, 230, 136)',
    'rgb(62, 68, 71)',
    'rgb(101, 119, 130)',
    'rgb(168, 168, 186)',
    'rgb(204, 204, 204)',
    'rgb(209, 209, 209)',
  ],
}
