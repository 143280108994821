<template>
  <b-modal
    id="upgradPlanModal"
    ref="upgradPlanModal"
    ok-only
    ok-title="View plans"
    @ok="ok"
  >

    <h2>You've reached your invoice limit</h2>
    <span>Upgrade to the Premium plan to create more invoices</span>
    <h5 class="mt-2">
      When you upgrade to Premium, you'll also get:
    </h5>
    <ul class="mt-1">
      <li>Unlimited invoices</li>
      <li>Unlimited clients</li>
    </ul>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {

    show() {
      this.$refs.upgradPlanModal.show()
    },
    close() {
      this.$refs.upgradPlanModal.hide()
    },
    ok() {
        this.$destroy()
        this.$router.replace({ name: 'subscription' })
    },

  },

}
</script>
