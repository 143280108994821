import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/invoices/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoices(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoices', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoices', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoices-send-mail', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    markAsPaid(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoices-mark-as-paid', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // payment types
    fetchPaymentTypes(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/payment-methods', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    recurringTest(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/recurring-invoices-today-with-date', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/invoices-duplicate/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateRecurringInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/recurring-invoices-duplicate/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
