<template>

  <div v-if="invoiceData.design">
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <validation-observer

          ref="fromSendData"
        > <b-form
          class="p-2"
        >

          <validation-provider
            #default="{errors}"
            :name="$t('To')"
            rules="required"
          >
            <!-- Name -->
            <b-col cols="12">

              <b-form-group
                label="To"
                label-cols-md="2"
                label-for="mailto"
              >
                <div class="d-flex align-items-center justify-content-center column-is-mobile">

                  <b-form-tags
                    v-model="invoiceSendData.receivers"
                    :add-on-change="true"
                    size="lg"
                    input-id="mailto"
                    :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"

                    placeholder="Enter a receiver mail and press enter..."
                    :tag-validator="emailListValidation"
                    invalid-tag-text="Invalid Email Format"
                    separator=" "
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-button
                    v-if="!showCc"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class="ml-1"
                    variant="outline-primary"
                    size="sm"
                    @click="showCc=true"
                  >
                    Cc
                  </b-button>
                  <b-button
                    v-if="!showBcc"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class="ml-1"
                    variant="outline-primary"
                    size="sm"
                    @click="showBcc=true"
                  >
                    Bcc
                  </b-button>
                </div>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <validation-provider
            v-if="showCc"
            #default="{errors}"
            :name="$t('Cc')"
          >
            <!-- Name -->
            <b-col cols="12">

              <b-form-group
                label="Cc"
                label-cols-md="2"
                label-for="mail-cc"
              >
                <b-form-tags
                  v-model="invoiceSendData.cc"
                  invalid-tag-text="Invalid Email Format"
                  size="lg"
                  input-id="mail-cc"
                  :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                  :add-on-change="true"
                  :placeholder="$t('Enter a cc mail...')"
                  :tag-validator="emailListValidation"
                  separator=" "
                  :state="errors.length > 0 ? false:null"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <validation-provider
            v-if="showBcc"
            #default="{errors}"
            :name="$t('Bcc')"
          >
            <!-- Name -->
            <b-col cols="12">

              <b-form-group
                label="Bcc"
                label-cols-md="2"
                label-for="mail-bcc"
              >
                <b-form-tags
                  v-model="invoiceSendData.bcc"
                  size="lg"
                  input-id="mail-bcc"
                  :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                  :add-on-change="true"
                  placeholder="Enter a bcc mail..."
                  :tag-validator="emailListValidation"
                  separator=" "
                  :state="errors.length > 0 ? false:null"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <validation-provider
            #default="{errors}"
            :name="$t('Subject')"
            rules="required"
          >
            <!-- Name -->
            <b-col cols="12">

              <b-form-group
                label="Subject"
                label-cols-md="2"
                label-for="subject"
              >

                <b-form-input
                  id="subject"
                  v-model="invoiceSendData.subject"
                  size="lg"
                  placeholder="Enter a mail subject..."
                  :state="errors.length > 0 ? false:null"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <validation-provider
            #default="{errors}"
            :name="$t('Content')"
            rules="required"
          >
            <!-- Name -->
            <b-col cols="12">

              <b-form-group
                label="Content"
                label-cols-md="2"
                label-for="content"
              >

                <b-form-textarea
                  id="subject"
                  v-model="invoiceSendData.content"
                  size="lg"
                  rows="4"
                  placeholder="Enter a mail subject..."
                  :state="errors.length > 0 ? false:null"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <b-row>

            <b-col md="12">
              <h4>Attachments</h4>
              <hr>
            </b-col>
            <b-col md="12">
              <div class="d-flex justify-content-start flex-wrap">

                <div>
                  <div class="text-center bg-primary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center  ml-50 my-1 shadow">

                    <feather-icon

                      icon="FileTextIcon"
                      size="60"
                    />

                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <span>Invoice_{{ invoiceData.design.options.numbers.invoiceNumberPrefix }}{{ invoiceData.content.invoice_number }}.pdf</span>
                  </div>
                </div>
                <div
                  v-for="item,index in invoiceSendData.attachments"
                  :key="index"
                  class="ml-2"
                >
                  <div

                    class="text-center bg-primary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center  ml-50 my-1 shadow"
                  >

                    <feather-icon

                      icon="FileTextIcon"
                      size="60"
                    />

                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <span>{{ item.original_file_name }}</span>
                    <span class="text-muted">{{ $helpers.bytesToHumanFileSize(item.file_size) }}</span>
                  </div>
                </div>
                <file-uploader
                  ref="fileUploader"
                  :accepts="'.pdf'"
                  @fileUploaded="fileUploaded"
                />
                <b-link

                  class="ml-2"
                  @click="addAttachments"
                >

                  <div class="text-center bg-primary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center  ml-50 my-1 shadow">

                    <feather-icon

                      icon="PaperclipIcon"
                      size="30"
                    />
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <span>Add File</span>
                  </div>
                </b-link>
              </div>
            </b-col>
          </b-row>
          <!--/ Name -->
          <div class="d-flex justify-content-between align-items-center mt-2">
            <div />
            <b-button

              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="primary"
              size="lg"
              :disabled="invoiceSendData.receivers.length<=0"
              @click="saveData"
            >
              Send Invoice
            </b-button>
          </div>
        </b-form>
        </validation-observer>
      </b-col>

    </b-row>

  </div>

</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BFormInvalidFeedback, BButton, BFormInput, BFormTextarea, BLink, BFormTags,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import fileUploader from '@core/components/file-uploader/FileUploader.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BLink,
    BFormTextarea,
    BCol,
    BFormTags,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BButton,
    BFormInput,
    fileUploader,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCc: false,
      showBcc: false,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Discount updated',
      errorData: null,
      blankInvoiceSendData: {
        subject: '',
        content: 'Thank you for your business.',
        bcc: [],
        cc: [],
        receivers: [],
        attachments: [],
      },
      invoiceSendData: null,
    }
  },
  computed: {

    userData() {
      return this.$store.state.app.userData
    },
  },
  watch: {
    'invoiceData.content.billing': {
      deep: true,
      handler() {
        this.resetForm()
      },
    },
  },

  created() {
    this.resetForm()
  },

  methods: {
    // eslint-disable-next-line no-shadow
    emailListValidation(email) {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (email.match(mailformat)) {
        return true
      }

      return false
    },
    addAttachments() {
      this.$refs.fileUploader.openUploader()
    },
    fileUploaded(fileData) {
      this.invoiceSendData.attachments.push(fileData)
    },
    saveData() {
      this.$refs.fromSendData.validate().then(success => {
        if (success) {
          this.$emit('handleSend', this.invoiceSendData)
        }
      })
    },
    resetForm() {
      this.invoiceSendData = JSON.parse(JSON.stringify(this.blankInvoiceSendData))
      if (this.invoiceData.content.billing) {
        this.invoiceSendData.receivers = [this.invoiceData.content.billing.email]
        this.showCc = true
        if (this.userData.email) {
          this.invoiceSendData.cc = [this.userData.email]
        }
      }
      this.invoiceSendData.subject = `Invoice  from ${this.invoiceData.content.company_info.name}`
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
