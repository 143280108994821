<template>

  <div v-if="invoiceData.design">
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-row>
      <b-col
        md="12"
        lg="12"
      >

        <validation-observer

          ref="refFormRecurringData"
        > <b-form
          class="p-2"
        >
          <b-col
            cols="12"
            class="p-0"
          >
            <b-form-checkbox
              v-model="recurringInvoiceData.enable_recurring"
              checked="true"
              name="check-button"
              switch
              inline
              :value="1"
              :unchecked-value="0"
            >
              <h4>
                Enable Recurring Mode
              </h4>
            </b-form-checkbox>
          </b-col>
          <hr>
          <div v-if="recurringInvoiceData.enable_recurring">

            <b-col
              md="12"
              lg="12"
            >

              <validation-provider
                #default="{errors}"
                :name="$t('Recurring Period')"
                rules="required"
              >
                <!-- Name -->
                <b-col
                  cols="12"
                  class="p-0"
                >

                  <b-form-group
                    label="Recurring Period"
                    label-for="recurring-period"
                    label-cols-md="2"
                  >
                    <v-select
                      v-model="recurringInvoiceData.recurring_period"

                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="recurringPeriodOptions"
                      :reduce="val => val.value"
                      autocomplete="chrome-off"
                      :clearable="false"
                      input-id="recurring-period"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                #default="{errors}"
                :name="$t('Start On')"
                rules="required"
              >
                <!-- Name -->
                <b-col
                  cols="12"
                  class="p-0"
                >

                  <b-form-group
                    label="Start On"
                    label-for="recurring-start-date"
                    label-cols-md="2"
                  >
                    <b-form-datepicker
                      id="recurring-start-date"
                      v-model="recurringInvoiceData.start_date"

                      local="en"
                      today-variant="danger"
                      :state="errors.length > 0 ? false:null"
                      @input="calculateOccurrences"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>

              <!-- Name -->
              <b-col
                cols="12"
                class="p-0"
              >
                <b-form-group
                  label="Number of occurences"
                  label-cols-md="2"
                  label-for="subject"
                >

                  <div class="d-flex align-items-center justify-content-center column-is-mobile">

                    <b-form-input
                      id="subject"
                      v-model="recurringInvoiceData.number_of_occurences"
                      v-numeric-only
                      type="number"
                      size="md"
                      placeholder="Enter number of occurrences"
                      min="1"
                      step="1"
                      :disabled="recurringInvoiceData.recur_until_cancel === 1"
                      @input="calculateEndDate"
                    />

                    <b-form-checkbox
                      v-model="recurringInvoiceData.recur_until_cancel"
                      class="ml-3 mt-1"
                      checked="true"
                      name="check-button"
                      inline
                      :value="1"
                      :unchecked-value="0"
                      @input="calculateEndDate"
                    >
                      <h4>
                        Recurring Until Canceled
                      </h4>
                    </b-form-checkbox>
                  </div>
                </b-form-group>

              </b-col>

              <validation-provider
                #default="{errors}"
                name="$t('Ends On (Optional)')"
              >
                <!-- Name -->
                <b-col
                  cols="12"
                  class="p-0"
                >

                  <b-form-group
                    :label="$t('Ends On (Optional)')"
                    label-for="recurring-end-date"
                    label-cols-md="2"
                  >
                    <b-form-datepicker
                      id="recurring-end-date"
                      v-model="recurringInvoiceData.end_date"
                      :disabled="recurringInvoiceData.recur_until_cancel === 1"
                      local="en"
                      today-variant="danger"
                      :state="errors.length > 0 ? false:null"
                      :min="recurringInvoiceData.start_date"
                      @input="calculateOccurrences"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="p-0"
            >
              <b-form-checkbox
                v-model="recurringInvoiceData.send_mail_settings.enabled"
                checked="true"
                name="check-button"
                switch
                inline
                :value="1"
                :unchecked-value="0"
              >
                <h4>
                  Send invoice as email
                </h4>
              </b-form-checkbox>
            </b-col>
            <hr>
            <b-col
              v-if="recurringInvoiceData.send_mail_settings.enabled"
              md="12"
              lg="12"
            >
              <validation-provider
                #default="{errors}"
                :name="$t('To')"
                rules="required"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="To"
                    label-cols-md="2"
                    label-for="mailto"
                  >
                    <div class="d-flex align-items-center justify-content-center column-is-mobile">

                      <b-form-tags
                        v-model="recurringInvoiceData.send_mail_settings.receivers"
                        :add-on-change="true"
                        size="lg"
                        input-id="mailto"
                        :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"

                        placeholder="Enter a receiver mail and press enter..."
                        :tag-validator="emailListValidation"
                        separator=" "
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-button
                        v-if="!showCc"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="ml-1"
                        variant="outline-primary"
                        size="sm"
                        @click="showCc=true"
                      >
                        Cc
                      </b-button>
                      <b-button
                        v-if="!showBcc"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="ml-1"
                        variant="outline-primary"
                        size="sm"
                        @click="showBcc=true"
                      >
                        Bcc
                      </b-button>
                    </div>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                v-if="recurringInvoiceData.send_mail_settings.enabled && showCc"
                #default="{errors}"
                :name="$t('Cc')"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Cc"
                    label-cols-md="2"
                    label-for="mail-cc"
                  >
                    <div class="d-flex align-items-center justify-content-center column-is-mobile">

                      <b-form-tags
                        v-model="recurringInvoiceData.send_mail_settings.cc"
                        size="lg"
                        input-id="mail-cc"
                        :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                        :add-on-change="true"
                        :placeholder="$t('Enter a cc mail...')"
                        :tag-validator="emailListValidation"
                        separator=" "
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="ml-1"
                        variant="outline-warning"
                        size="sm"
                        @click="recurringInvoiceData.send_mail_settings.cc = null; showCc=false;"
                      >
                        Remove
                      </b-button>
                    </div>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider

                v-if="recurringInvoiceData.send_mail_settings.enabled && showBcc"
                #default="{errors}"
                :name="$t('Bcc')"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Bcc"
                    label-cols-md="2"
                    label-for="mail-bcc"
                  >
                    <div class="d-flex align-items-center justify-content-center column-is-mobile">

                      <b-form-tags
                        v-model="recurringInvoiceData.send_mail_settings.bcc"
                        size="lg"
                        input-id="mail-bcc"
                        :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                        :add-on-change="true"
                        placeholder="Enter a bcc mail..."
                        :tag-validator="emailListValidation"
                        separator=" "
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="ml-1"
                        variant="outline-warning"
                        size="sm"
                        @click="recurringInvoiceData.send_mail_settings.bcc = null; showBcc=false;"
                      >
                        Remove
                      </b-button>
                    </div>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                v-if="recurringInvoiceData.send_mail_settings.enabled"
                #default="{errors}"
                :name="$t('Subject')"
                rules="required"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Subject"
                    label-cols-md="2"
                    label-for="subject"
                  >

                    <b-form-input
                      id="subject"
                      v-model="recurringInvoiceData.send_mail_settings.subject"
                      size="lg"
                      placeholder="Enter a mail subject..."
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>
              <validation-provider
                v-if="recurringInvoiceData.send_mail_settings.enabled"
                #default="{errors}"
                :name="$t('Content')"
                rules="required"
              >
                <!-- Name -->
                <b-col cols="12">

                  <b-form-group
                    label="Content"
                    label-cols-md="2"
                    label-for="content"
                  >

                    <b-form-textarea
                      id="subject"
                      v-model="recurringInvoiceData.send_mail_settings.content"
                      size="lg"
                      rows="4"
                      placeholder="Enter a mail subject..."
                      :state="errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>
              </validation-provider>

            </b-col>
            <!--/ Name -->
            <div class="d-flex justify-content-between align-items-center mt-2">
              <div />
              <b-button

                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="primary"
                size="lg"
                @click="saveData"
              >
                Save Recurring Settings
              </b-button>
            </div>
          </div>
        </b-form>
        </validation-observer>

      </b-col>

    </b-row>

  </div>

</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BFormInvalidFeedback, BButton, BFormInput, BFormTextarea, BFormTags, BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BFormTextarea,
    BCol,
    BFormTags,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BFormInput,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCc: false,
      showBcc: false,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Discount updated',
      errorData: null,
      recurringPeriodOptions: [
        { value: 1, label: 'Monthly' },
        { value: 2, label: 'BiWeekly' },
        { value: 3, label: 'Weekly' },
        { value: 4, label: 'Daily' },
      ],
      blankRecurringSettingData: {
        enable_recurring: false,
        number_of_occurences: 1,
        recur_until_cancel: 0,
        recurring_period: 1,
        start_date: null,
        end_date: null,
        send_mail_settings: {
          enabled: false,
          subject: '',
          content: '',
          enable_send_mail: false,
          receivers: [],
          attachments: [],
          cc: [],
        },
      },
      recurringInvoiceData: null,
    }
  },
  computed: {

    userData() {
      return this.$store.state.app.userData
    },
  },
  watch: {
    'invoiceData.content.billing': {
      deep: true,
      handler() {
        this.resetForm()
      },
    },
  },
  created() {
    this.resetForm()
  },
  methods: {
      calculateEndDate() {
      // Retrieve the start date, number of occurrences, and recurring period from your data model
      const startDate = this.recurringInvoiceData.start_date // Start date in moment format
      const occurrences = this.recurringInvoiceData.number_of_occurences // Number of occurrences (e.g., 5, 10, etc.)
      const validUntilCancel = this.recurringInvoiceData.recur_until_cancel // Number of occurrences (e.g., 5, 10, etc.)
      this.recurringCheck = this.recurringInvoiceData.recur_until_cancel === 1
      if (validUntilCancel === 1) {
        this.recurringInvoiceData.end_date = null
        this.recurringInvoiceData.number_of_occurences = null
      } else {
        if (occurrences === 1) {
          this.recurringInvoiceData.end_date = startDate
        } else if (occurrences < 1 && occurrences != null) {
            this.recurringInvoiceData.end_date = null
            this.recurringInvoiceData.number_of_occurences = null
            this.recurringInvoiceData.recur_until_cancel = 1
          }

          if (occurrences > 1) {
            const recurringPeriod = this.recurringInvoiceData.recurring_period // Recurring period value (e.g., 1 for Monthly, 2 for BiWeekly, etc.)

              // Clone start date to avoid modifying original date
              let endDate = this.$moment(startDate)

              // Calculate end date based on the recurring period
              switch (recurringPeriod) {
                case 1: // Monthly
                  endDate = endDate.add(occurrences - 1, 'months')
                  break
                case 2: // BiWeekly (every 2 weeks)
                  endDate = endDate.add(((occurrences - 1) * 2), 'weeks')
                  break
                case 3: // Weekly
                  endDate = endDate.add(occurrences - 1, 'weeks')
                  break
                case 4: // Daily
                  endDate = endDate.add(occurrences - 1, 'days')
                  break
                default:
                  return null // Return null if the period is not valid
              }

              // Set the calculated end date back to your data model
              const formattedEndDate = endDate.format('YYYY-MM-DD')
              this.recurringInvoiceData.end_date = formattedEndDate
          }
        }

      return true
    },
    calculateOccurrences() {
      // Retrieve the start date, end date, and recurring period from your data model
      const startDate = this.$moment(this.recurringInvoiceData.start_date) // Start date as moment object
      const endDate = this.$moment(this.recurringInvoiceData.end_date) // End date as moment object
      const recurringPeriod = this.recurringInvoiceData.recurring_period // Recurring period value (e.g., 1 for Monthly, 2 for BiWeekly, etc.)

      // Check if start date and end date are valid
      if (!startDate.isValid() || !endDate.isValid() || startDate.isAfter(endDate)) {
        this.recurringInvoiceData.number_of_occurences = 0
        return
      }

      // Calculate occurrences based on the recurring period
      let occurrences = 0

      switch (recurringPeriod) {
        case 1: // Monthly
          occurrences = endDate.diff(startDate, 'months') + 1 // Include start month
          break
        case 2: // BiWeekly (every 2 weeks)
          occurrences = Math.floor(endDate.diff(startDate, 'weeks') / 2) + 1
          break
        case 3: // Weekly
          occurrences = endDate.diff(startDate, 'weeks') + 1 // Include start week
          break
        case 4: // Daily
          occurrences = endDate.diff(startDate, 'days') + 1 // Include start day
          break
        default:
          occurrences = 0
      }

      // Set the calculated number of occurrences back to your data model
      this.recurringInvoiceData.number_of_occurences = occurrences
    },

    // eslint-disable-next-line no-shadow
    emailListValidation(email) {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (email.match(mailformat)) {
        return true
      }

      return false
    },

    saveData() {
      this.$refs.refFormRecurringData.validate().then(success => {
        if (success) {
          if (this.recurringInvoiceData.number_of_occurences != null || this.recurringInvoiceData.recur_until_cancel !== 0) {
            this.$emit('handleRecurringInvoice', this.recurringInvoiceData)
          } else {
            this.$swal({
              title: 'Are you sure?',
              text: "You haven't entered a number of occurrences. The invoice will recur until cancelled.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Confirm!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$emit('handleRecurringInvoice', this.recurringInvoiceData)
              }
            })
          }
        }
      })
    },
    resetForm() {
      this.recurringInvoiceData = JSON.parse(JSON.stringify(this.blankRecurringSettingData))
      if (this.invoiceData.content.billing != null) {
        this.recurringInvoiceData.send_mail_settings.receivers = [this.invoiceData.content.billing.email]
        this.showCc = true
        if (this.userData.email) {
          this.recurringInvoiceData.send_mail_settings.cc = [this.userData.email]
        }
      }
      if (this.invoiceData.content.recurring_settings != null) {
      this.recurringInvoiceData = this.invoiceData.content.recurring_settings

      if (this.invoiceData.content.recurring_settings.enabled === false) {
        this.recurringInvoiceData.enable_recurring = 0
        this.blankRecurringSettingData.enable_recurring = 0
      } else {
        this.recurringInvoiceData.enable_recurring = 1
      this.blankRecurringSettingData.enable_recurring = 1
      }
      }
      this.recurringInvoiceData.send_mail_settings.subject = `Invoice #${this.invoiceData.content.invoice_number} from ${this.invoiceData.content.company_info.name}`
      if (!this.invoiceData.id) {
        const today = this.$moment()
        this.recurringInvoiceData.start_date = today.format('YYYY-MM-DD')
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
